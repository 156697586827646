import React from 'react'

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  return (
    browser && (
      <div className='x error__page df jcc aic'>
        <h2>Spiceless</h2>
      </div>
    )
  )
}

export default NotFoundPage
